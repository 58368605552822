import mammoth from 'mammoth';
import Strings from "../../../utils/Strings"
import MarkdownFileImport from "./fileImport/MarkdownFileImport"
import TxtFileImport from "./fileImport/TxtFileImport"
import XMindFileImport from "./fileImport/XMindFileImport"
import strings from "../../../common/lang/strings"
import getString from "../../../common/lang/language";
import {
    postMindmapOcrScan,
} from "../../../common/netWork/mind_map_api";
import { ocrSupportedScanImgTypes } from "../../../assets/js/GlobalVariable.js";
import XJMindFIleImport from './fileImport/XJMindFIleImport';
class SaveMindFile {
    constructor(delegate) {
        this.delegate = delegate;
        this.FileType = ".ysmind";
        this.XMindFileType = ".xmind";
        this.XJMindFileType = ".xjmm";
        this.MarkdownFileType = ".md";
        this.TxtFileType = ".txt";
        this.DocxFileType = ".docx";
    }

    openMindFile(file) {
        if (file) {
            let fileName = file.name;
            let fileType = fileName.substring(fileName.lastIndexOf('.'));
            if (fileName.endsWith(this.FileType)) {
                // this.originalDocumentImport(text);
            } else if ((fileName.endsWith(this.MarkdownFileType)) ||
                fileName.endsWith(this.TxtFileType)) {//txt
                this.readTexFile(file);
            } else if (fileName.endsWith(this.DocxFileType)) {//docx
                this.readDocxFile(file);
            } else if (fileName.endsWith(this.XMindFileType)) {//xmind
                this.importXMindFile(file);
            } else if (fileName.endsWith(this.XJMindFileType)) {//xjmind
                this.importXJMindFile(file);
            } else if (ocrSupportedScanImgTypes.includes(fileType)) {//ocr
                this.readOcrScanFile(file);
            } else {
                this.delegate.showModal = false;//隐藏导入文件弹窗
                this.delegate.$message.error(getString(strings.Not_Supported_At_The_Moment) + fileType + getString(strings.Mind_File));
                setTimeout(() => {
                    this.delegate.reload();
                }, 500);
            }
        }
    }

    //读取ocr扫描图片
    readOcrScanFile(file) {
        if (file) {
            let reader = new FileReader();
            reader.onload = (data) => {
                let blob = new Blob([data.target.result], { 'type': file.type });
                this.setOcrScanImg(blob);
            };
            reader.readAsArrayBuffer(file);
        }
    }
    setOcrScanImg(blob) {
        const formData = new FormData();
        formData.append('image', blob);
        formData.append('type', 'CREATE');//CREATE（扫码创建新导图）,TEXT（在节点或备注中输入扫码后的文字）
        postMindmapOcrScan(
            formData,
            (res) => {
                let text = res.content;
                if (text.length == 0) {
                    this.delegate.$message.error(getString(strings.Mind_Group_The_Content_Cannot_Be_Empty));
                    return;
                }
                this.txtImport(text);
            },
            (error) => {
                if (error.code == 179) {//掃碼次數已用完請充值會員
                    let that = this;
                    this.delegate.$confirm({
                        title: getString(strings.Mind_Edit_Upgrade),
                        content: getString(strings.Recharge_Members_Can_Get_More_Use_Times), //充值会员可获得更多使用次数。
                        centered: true,
                        maskClosable: true,
                        okText: getString(strings.Mind_Go_Now),
                        cancelText: getString(strings.Global_Cancel),
                        onOk() {
                            that.delegate.upgradeMember();
                        },
                        onCancel() {
                        },
                    });
                } else {
                    this.delegate.$message.error(error.desc);
                }
                this.delegate.reload();//隐藏导入文件弹窗
            },
        );

    }

    readTexFile(file) {
        // 使用 FileReader 来读取文件
        let reader = new FileReader()
        // reader.readAsText(file, 'gb2312')    // 读取纯文本文件,且编码格式为 gb2312
        reader.readAsArrayBuffer(file);
        // 读取文件,会触发 onload 异步事件 
        reader.onload = (e) => {
            let ints = new Uint8Array(e.target.result); //要使用读取的内容,所以将读取内容转化成uint8Array
            ints = ints.slice(e, ints.length); //截取一段读取的内容
            let text = new TextDecoder('utf-8').decode(ints); //二进制缓存区内容转化成中文(即也就是读取到的内容 
            if (text.length == 0) {
                this.delegate.$message.error(getString(strings.Mind_Group_The_Content_Cannot_Be_Empty));
                return;
            }
            this.txtImport(text);
        }
    }

    readDocxFile(file) {
        // 使用 FileReader 来读取文件
        let reader = new FileReader()
        // 读取文件,会触发 onload 异步事件 
        reader.onload = (loadEvent) => {
            const arrayBuffer = loadEvent.target['result'];
            mammoth
                .convertToMarkdown({ arrayBuffer: arrayBuffer })//返回markdown格式
                .then((resultObject) => {
                    let text = resultObject.value
                    if (text.length == 0) {
                        this.delegate.$message.error(getString(strings.Mind_Group_The_Content_Cannot_Be_Empty));
                        return;
                    }
                    // const base64 = 'base64';
                    // const base64str = '(data:';
                    // let base64s = text.indexOf('![')
                    // let base64e = text.indexOf(')', base64s)
                    // let flag = (base64s == -1) ? false : true;
                    // let base64Stop = -1;
                    // while (flag) {
                    //     let base64String = text.substring(base64s, base64e + 1)
                    //     if (base64String.includes(base64) || base64String.includes(base64str)) {
                    //         text = text.replace(base64String, '')
                    //     }
                    //     base64s = text.indexOf('![')
                    //     base64e = text.indexOf(')', base64s)
                    //     if (base64s == -1) {
                    //         flag = false;
                    //     }
                    //     base64Stop++
                    //     if (base64Stop > 1000) {
                    //         flag = false;
                    //     }
                    // }
                    this.markdownImport(text);
                })
                .done();
        }
        reader.readAsArrayBuffer(file);
        return false;
    }

    importXMindFile(path) {
        new XMindFileImport(this.delegate, path);
    }

    importXJMindFile(path) {
        new XJMindFIleImport(this.delegate, path, this.delegate.$router.appPath);
    }

    markdownImport(text) {
        if (new Strings().isEmpty(text)) {
            return;
        }
        new MarkdownFileImport(this.delegate, text).format();
    }

    txtImport(text) {
        if (new Strings().isEmpty(text)) {
            return;
        }
        new TxtFileImport(this.delegate, text).format();
    }
}
export default SaveMindFile