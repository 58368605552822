<template>
  <div id="my-ct-move-file">
    <div class="my-ct-move-box">
      <div class="my-ct-move-header">
        <span class="newfile-text">{{getString(strings.User_Move_To)}}</span>
        <span class="newfile-close-icon" @click="closeMoveFileModel">
          <!-- <a-icon type="close" :style="{ fontSize: '12px' }" /> -->
          <img class="close-button-img" 
            src="../../assets/img/popupsImg/colse_new.svg"  
             />
        </span>
      </div>

      <div class="my-ct-move-form">
        <div class="share-new-profile-box">
          <div
            class="share-new-profile"
            @click="newProfileToTemplate('newProFile')"
          >
            <img
              src="../../../static/img/userData/new_profile_logo.png"
              alt=""
            />
            <span>{{getString(strings.User_New_Group)}}</span>
          </div>
        </div>
        <div class="profile-list-box">
          <div
            class="profile-list"
            v-for="(item, index) in initUserCollectionData.collections"
            :key="index"
            :class="proFileListIndex == index ? 'profile-list-click' : ''"
            @click="setProfileListStyle(index, item.id)"
          >
            <div class="profile-header">
              <div class="profile-img">
                <img
                  src="../../../static/img/userData/collection_proflie_logo.png"
                  alt=""
                />
              </div>
              <span>{{ item.name }}</span>
            </div>
            <div class="profile-body">
              <span>{{ timestampToTimeDHM(item.createTime) }}</span>
            </div>
          </div>
        </div>
        <a-form-item class="newfile-submit">
          <a-button @click="closeMoveFileModel">{{getString(strings.Global_Cancel)}}</a-button>
          <a-button type="primary" html-type="submit" @click="postMoveProfile">
            {{getString(strings.Global_Ok)}}
          </a-button>
        </a-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import {
  postUserMoveCollectItem,
  postUserMyCollections,
  postUserNewCollection,
  postUserRenameCollection,
} from "../../common/netWork/base_api";
import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";

import dataTimeToFormats from "../../utils/timestampToTime";
export default {
  data() {
    return {
      initUserCollectionData: "",
      proFileListIndex: 0,
      strings: strings,
    };
  },
  computed: {
    ...mapState({
      moveFileData: (state) => state.colltionMoveFile.data,
    }),
  },
  mounted() {
    this.postUserMyCollections(this.moveFileData.item);
  },
  methods: {
    ...mapMutations([
      "moveCollectionTemplateToNewFile",
      "colletionNewFileModel",
    ]), //语言包
    getString(i) {
      return getString(i);
    },
    //根据title请求收藏夹
    postUserMyCollections(item) {
      const type = item.type;
      postUserMyCollections(
        { type, lastCollectionId: "" },
        (initCollectionData) => {
          this.menuName = "";
          this.initUserCollectionData = initCollectionData;
          // console.log(this.initUserCollectionData);
        }
      );
    },
    //移动文件夹
    postMoveProfile() {
      postUserMoveCollectItem(
        {
          collectItemId: this.moveFileData.item.id,
          toCollectionId: this.toCollectionId,
        },
        (res) => {
          this.$message.success(getString(strings.Message_Tips_Mobile_Success));
          this.closeMoveFileModel();
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    //关闭窗口
    closeMoveFileModel() {
      this.moveCollectionTemplateToNewFile({ accout: false });
    },
    //新建文件到模板
    newProfileToTemplate() {
      const type = this.moveFileData.type;
      const style = "newProFileToTemplate";
      this.colletionNewFileModel({ accout: true, data: { type, style } });
    },
    //设置点击的文件夹样式
    setProfileListStyle(index, id) {
      this.proFileListIndex = index;
      this.toCollectionId = id;
    },
    //时间转化
    timestampToTimeDHM(timestamp) {
      return dataTimeToFormats.timestampToTimeDHM(timestamp);
    },
  },
};
</script>

<style lang="less" scoped>
#my-ct-move-file {
  width: 690px;
  height: 526px;
  min-height: 526px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.16);
  z-index: 1000;
  border-radius: 10px;
  .my-ct-move-box {
    // padding: 20px;
    .my-ct-move-header {
      padding: 6px 20px;
      background: #efefef;
      border-radius: 10px 10px 0 0;
      .newfile-text {
        display: flex;
        // justify-content: center;
        font-size: 18px;
        font-family: Noto Sans SC;
        /*font-weight: bold;*/
        line-height: 24px;
        color: #333333;
        opacity: 1;
      }
      .newfile-close-icon {
        position: absolute;
        right: 20px;
        top: 7px;
        cursor: pointer;
        transition: 0.3s;
        color: #333333;
      }
      .newfile-close-icon:hover {
        transition: 0.3s;
        color: #fd492b;
        transform: rotate(360deg);
      }
    }
    .my-ct-move-form {
      margin-top: 20px;
      .share-new-profile-box {
        padding:0 20px;
        .share-new-profile {
          display: flex;
          justify-content: start;
          align-items: center;
          cursor: pointer;
          img {
            margin-right: 10px;
          }
        }
      }
      .profile-list-box {
        height: 355px;
        max-height: 355px;
        overflow: auto;
        width: 648px;
        margin-left: 20px;
        margin-bottom: 20px;
        margin-top: 20px;
        background: #f8f8f8;
        .profile-list {
          width: 648px;
          height: 60px;
          opacity: 1;
          transition: 0.5s;
          margin: 15px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .profile-header {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            .profile-img {
              width: 40px;
              height: 40px;
              background: #ffffff;
              box-shadow: 1px 1px 3px #d1d1d1;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 12px;
                height: 12px;
              }
            }

            span {
              font-size: 14px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 24px;
              color: #666666;
              margin-left: 10px;
            }
          }
          .profile-body {
            margin-right: 20px;
          }
        }
        .profile-list:hover {
          transition: 0.5s;
          background: #d3d3d3;
        }
        .profile-list-click {
          transition: 0.5s;
          background: #acacac;
        }
      }
      .newfile-submit {
        display: flex;
        justify-content: center;
        button {
          width: 80px;
          height: 42px;
          opacity: 1;
          border-radius: 2px;
          margin: 0 20px;
        }
      }
    }
  }
}
.close-button-img{
    width:14px;
    cursor: pointer;
    opacity: 0.5;
}
.close-button-img:hover{
    opacity: 1;
}
</style>