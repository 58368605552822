<template>
    <main id="more-content-box" ref="mainBox">
        <div v-show="spinning" class="loading-box">
            <a-spin
            class="loading-child"
            tip="Loading..."
            :spinning="spinning"
            :delay="500"
            >
            </a-spin>
        </div>
        <div class="chips-content">
        <!-- 共享组 -->
        <article class="hot-group">
            <section class="shared-group-box">
            <div class="shared-group-title">
                <div class="home-back">
                    <img class="back-img" src="../../../assets/img/storm/icon/homeBlack.svg" @click="backFun" width="22px">
                    <img
                    class="load-img"
                    src="../../../assets/img/myMindIcon/right-arrow.svg"
                    alt=""
                    />
                </div>
                <h2 class="hot-group-name">
                    {{ groupTypeName }}
                </h2>
            </div>
            <div class="shared-group-container">
                <div class="shared-group-item" v-for="(item,index) in moreGroupData" :key="item.id+index" @click="goToGroupDetails(item.id)">
                <img class="shared-group-img" :src="httpImageToPrefix(item.cover)" :alt="item.name">
                <div class="shared-group-name" :title="item.name">
                    {{item.name}}
                </div>
                <div class="shared-group-number">
                    <span>{{item.memberCount}}{{getString(strings.Mind_Group_Number_Of_People)}}</span>
                    <div class="dot"></div>
                    <span>{{item.gmapCount}}{{getString(strings.Mind_Group_Individual)+getString(strings.Mind_Group_Mind_Mapping)}}</span>
                </div>
                <div class="shared-group-introduce" :title="item.desc">
                    {{item.desc}}
                </div>
                <div class="add-button" @click.stop>
                    <!-- 判断加入共享组是否需要付费加入或验证消息，如果需要则下方按钮为“申请加入”，不需要则为“立即加入；如果已加入，则显示已加入” -->
                    <button class="join-now" v-if="((!item.isJoined) && (!item.needVerification) && (item.mbeansRequired == 0))"
                    @click="immediatelyToJoin(item.id,index)">
                    {{ getString(strings.Mind_Group_Immediately_To_Join) }}
                    </button>
                    <button class="join-now" v-else-if="!item.isJoined && item.mbeansRequired && item.mbeansRequired != 0"
                    @click="applyToJoin(item,1,index)">
                      <label class="cursor-pointer">{{ getString(strings.Mind_Group_Immediately_To_Join) }}</label>
                      <label class="cursor-pointer" style="margin-left: 6px;">{{ item.mbeansRequired }}</label>
                      <img src="../../../assets/img/user/member/mBean.svg" alt="" width="14px" style="margin:2px 0 0 3px;">
                    </button>
                    <button class="join-now" v-else-if="!item.isJoined && item.needVerification"
                    @click="applyToJoin(item,2)">
                    {{ getString(strings.Mind_Group_Apply_To_Join) }}
                    </button>
                    <button type="button" disabled class="joined" v-else>
                    {{ getString(strings.Mind_Group_Joined) }}
                    </button>
                </div>
                </div>
            </div>
            </section>
        </article>
        </div>
        <footer>
          <div class="load-more" @click="loadMoreGroupData" v-if="loadStatus=='load'">
            {{getString(strings.Load_More)}}
          </div>
          <div class="loading" v-else-if="loadStatus=='loading'">
            {{getString(strings.Loading)}}
          </div> 
          <div class="it-over" v-else-if="loadStatus=='over'">
            {{getString(strings.It_Is_Over)}}~
          </div> 
        </footer>
        <!-- 申请加入共享组弹框 -->
        <ApplyJoinGroup 
        :tagModalShow="applyJoinModalShow"
        :groupItem="groupItem"
        @hideModal="hideModal"
        @applyJoinGroup="applyJoinGroup"/>  
  </main>
</template>
<script>
import { mapMutations } from "vuex";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import httpImageToPrefix from '../../../utils/httpImageToPrefix';
import {
 postCrispsGetTagPage,postCrispsHomeData
} from "../../../common/netWork/crisps_api";
import { postGroupJoin} from "../../../common/netWork/group_api";
import { getJwt } from "@/common/netWork/base";

import ApplyJoinGroup from './ApplyJoinGroup';
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}
function ajaxPromise(callback,params,that){
  return new Promise(function (resolve, reject) {
    callback(
      params,
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
        that.$message.error(error.desc);
      }
    );
  });
}
let tagId = '';
export default {
  components:{
    ApplyJoinGroup
  },
  props:[],
  data() {
    return {
        strings, //语言包
        spinning: false,
        nowElectron: false,
        groupTypeName:'热门组',
        moreGroupData:[],// 共享组数据
        groupItem:{},//点击加入按钮的共享组数据
        applyJoinModalShow: false, //申请加入共享组弹框
        groupTagName: '', //申请理由
        loadMoreDataTime :null,
        loadMoreDataFlag: false, //是否可以请求列表数据
        lastId:'',
        loadStatus:'load'

    };
  },
  created() {
    tagId =  this.$route.query.tag || '';
    this.groupTypeName =  this.$route.query.type;

    this.nowElectron = this.$tools.isElectron();

    this.getFirstData();

  },
  methods: {
    ...mapMutations(["showLoginModel",]),
    getString(i) {
      return getString(i);
    },
    httpImageToPrefix(src) {
      return httpImageToPrefix(src)
    },
    backFun(){
      if (window.history.length > 1) {
        const stormBackRoute = JSON.parse(sessionStorage.getItem('stormBackRoute')) ;
        if (stormBackRoute) {
          this.$router.push(stormBackRoute)
        }
      } else {
        this.$router.push('/')
      }
    },
    //获取数据
    getFirstData(){
      this.spinning = true;
      const obj ={
        tagId,
        type:1,
        lastId:'',
      }
      this.moreGroupData=[];
      ajaxPromise(postCrispsGetTagPage,obj,this)
        .then((res) => {
         if (!!res) {
            let groupFeeds = res.groupFeeds 
            if (!!groupFeeds && groupFeeds.length>0) {
              let lastItem = groupFeeds[groupFeeds.length - 1]
              if (lastItem) {
                this.lastId = lastItem.id
                this.loadMoreDataFlag = true;
                this.loadMoreData();
              }
              this.moreGroupData = groupFeeds
            }
         }
        })
        .finally(() => {
          this.spinning = false;
        //   console.log(this.moreGroupData);
        });
    },
    loadMoreGroupData(){
      if (this.loadMoreDataFlag) {
        this.loadStatus='loading'
          const obj ={
            tagId,
            type:1,
            lastId:this.lastId,
          }
          ajaxPromise(postCrispsGetTagPage,obj,this)
            .then((res) => {
             if (!!res) {
                let groupFeeds = res.groupFeeds || []; 
                if (!!groupFeeds && groupFeeds.length>0) {
                    let lastItem = groupFeeds[groupFeeds.length - 1]
                    if (lastItem) {
                        this.lastId = lastItem.id
                    }
                    this.moreGroupData = [...this.moreGroupData,...groupFeeds]
                    this.loadMoreDataFlag = true;
                    this.loadStatus='load'
                }else{
                    this.loadMoreDataFlag = false;
                    this.loadStatus='over'
                }
             }
            })
            .finally(() => {
            //   console.log(obj,this.moreGroupData);
            
            });
      }
    },
    loadMoreData() {
      this.$nextTick(() => {
        let box = this.$refs.mainBox;
        if (box) {
          const onMouseWheel = (e) => {
              clearTimeout(this.loadMoreDataTime);
              this.loadMoreDataTime = setTimeout(() => {
                let ev = e || window.event;
                let down = true; // 定义一个标志，当滚轮向下滚时，执行一些操作
                down = ev.wheelDelta ? ev.wheelDelta < 0 : ev.detail > 0;
                if (down) {
                  this.loadMoreGroupData();
                }
              }, 100);
          }
          box.removeEventListener("mousewheel",onMouseWheel,true);
          box.addEventListener("mousewheel",onMouseWheel,true);
        }
      })
    },
    goToGroupDetails(id){
      if (this.nowElectron) {
        let url = "/g/" + id;
        this.openMind(url);
      } else {
        let newWindow = window.open();
        const routerData = this.$router.resolve({
          path: "/g/" + id,
        });
        newWindow.location = routerData.href;
      }
    },
    openMind(item,type) {
      //打开导图
      ipcRenderer.send("openCalendarWindow", item, type);
    },
     //请求加入群组接口
    joinTheGroup(id,type,index) {
      if (getJwt()) {
        this.spinning = true;
        const obj ={
          groupId: id,
          message: this.groupTagName
        }
         postGroupJoin(obj,
          (res) => {
            this.spinning = false;
            if (type==0) {
              // this.getFirstData();
                this.$message.success(getString(strings.Mind_Group_Join_Successfully));
                this.moreGroupData[index].isJoined = true
            }else if (type==1) {
              this.$message.success(getString(strings.Mind_Group_Join_Successfully));
              this.moreGroupData[index].isJoined = true
            }else if (type==2) {
              this.$message.success(getString(strings.Mind_Group_Application_Sent_Successfully));
            }
            this.moreGroupData = JSON.parse(JSON.stringify(this.moreGroupData))
          },
          (error) => {
            this.spinning = false;
            if (type==1) {
            //   console.log(error);
              if (error.code==152) {//M豆数量不足
                let that = this;
                this.$confirm({
                  title: getString(strings.Mind_Tips),
                  content: getString(strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean),
                  centered: true,
                  onOk() {
                    that.bus.$emit('shoppingModel',true);
                  },
                  onCancel() {},
                });
                return
              }
            }
            this.$message.error(error.desc);
          });

      } else {
        this.showLoginModel(true);
      }
    },
    //立即加入
    immediatelyToJoin(id,index) {
      this.joinTheGroup(id,0,index);
    },
    // 显示加入共享组弹框
    applyToJoin(item,type,index) {
      this.groupItem = item;
      if (getJwt()) {
        if (type==1) {
          let that = this;
          this.$confirm({
            title: getString(strings.Mind_Tips),
            content: getString(strings.Mind_Shooping_Confirm_Payment)+item.mbeansRequired+getString(strings.Mind_MBean),
            centered: true,
            onOk() {
            //   console.log(item);
              that.groupTagName = '';
              that.joinTheGroup(that.groupItem.id,type,index);
            },
            onCancel() {},
          });
        }else{
          this.applyJoinModalShow = true
        }
      } else {
        this.showLoginModel(true);
      }
    },
    // 隐藏加入共享组弹框
    hideModal(type){
      if (type==1) {
          
        }else{
          this.applyJoinModalShow = false
        }
    },
    //申请加入
    applyJoinGroup(application){
      this.groupTagName = application;
      this.joinTheGroup(this.groupItem.id,2);
    },
  },
  

 
};
</script>

<style lang="less" scoped>



#more-content-box {
  width: 100%;
  height: inherit;
  background: #F5F5F5;
  margin: 0 auto;
  padding-bottom: 30px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 12px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
  /* 加载动画开始 */
  .loading-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .1);
    z-index: 300;
  }
  .loading-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(2);
  }
  .ant-spin-dot-item {
    background-color: #fd492b;
  }
  .ant-spin {
    color: #fd492b;
  }
  .ant-spin .ant-spin-dot-item {
    background-color: #fd492b !important;
  }
/* 加载动画结束 */
  .chips-content{
    max-width: 1200px;
    min-width: 1200px;
    margin: 0 auto ;
    background: #FFFFFF;
    article{
        width: 100%;
        padding: 20px;
    }
    .hot-group{
        background: #FFFFFF;
      .shared-group-box{
        width: 100%;
        margin-top: 20px;
        .shared-group-title{
          width: 100%;
          display: flex;
          align-items: center;
          .home-back{
              display: flex;
              align-items: center;
              .back-img{
                cursor: pointer;
              }
              .load-img {
                margin: 0 5px;
                margin-top: 2px;
                width: 16px;
                height: 16px;
                display: block;
            }
          }
          .hot-group-name{
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            margin: 0;
          }
        }
        .shared-group-container{
          width: 100%;
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .shared-group-item{
            width: 275px;
            padding: 20px;
            margin: 20px 15px 0 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #FFFFFF;
            box-shadow: 0px 0px 6px rgba(78, 57, 57, 0.1);
            cursor: pointer;
            &:hover{
              box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
            }
            
            border-radius: 4px;
            .shared-group-img{
              width: 84px;
              height: 84px;
              border-radius: 8px;
            }
            .shared-group-name{
              width: 100%;      
              text-align: center;        
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
              margin-top: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .shared-group-number{
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #999999;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 5px;

              .dot{
                width: 4px;
                height: 4px;
                background: #999999;
                border-radius: 50%;
                margin: 0 4px;
              }
            }
            .shared-group-introduce{
              width: 235px;
              height: 42px;
              text-align: center;
              margin-top: 20px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
              // white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display:-webkit-box;
              -webkit-line-clamp:2;
              -webkit-box-orient:vertical;
            }
            .add-button{
              margin-top: 24px;
              padding: 0 8px;
              min-width: 124px;
              height: 36px;
              // outline: none;
              font-size: 14px;
              .join-now {
                width: 100%;
                height: 100%;
                color: #333;
                background: #FFFFFF;
                border: 1px solid #FD492B;
                cursor: pointer;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover{
                  color: #fff;
                  background: #FD492B;
                }
              }
              .joined{ 
                width: 100%;
                height: 100%;
                border:0;
                background-color: #E8E8E8;
                color: #666666;
                border-radius: 4px;

              }
            }
          }
        }
      }
    }
  }   
}   
footer{
  display: flex;
  .load-more{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: #333333;
    margin: 20px auto;
    cursor: pointer;
  }
  .loading,.it-over{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: #666666;
    margin: 20px auto;
    user-select:none;
  
  }
}  
</style>
