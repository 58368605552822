
class HistoryForMyMindData {
    constructor() {
        this.alias = ""
        this.childrenNum = 0
        this.colorCode = 0
        this.cover = ""
        this.createTime = 1607078178
        this.hasMindKey = false
        this.id  = "5fca11224fc72826fb2fb461"
        this.isDir = false
        this.isSharing = false
        this.mindMapId = ""
        this.modifyTime = 1632030757
        this.name = ""
        this.parentId = ""
        this.parents = ""
        this.sheetNum = 0
        this.subtitle = 0
        this.isHistory = true
        this.path = "";
        this.localpath = "";
        this.isCloud = false;
    }
}
export default HistoryForMyMindData 
